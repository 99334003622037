<script setup lang="ts">
const accountStore = useAccountStore();
const staticLinks = useStaticLinks();
const isDesktop = useIsDesktop();
const router = useRouter();
const route = useRoute();

const { t } = useI18n({
  useScope: 'local',
});

const emit = defineEmits(['item-clicked']);

const roleId = computed(() => accountStore.user?.roleId || '');

const activeLink = computed(() => {
  const links = {
    watchlist: '/dashboard/matches?status=watchlist',
    otherFullInterest: '/dashboard/matches?status=otherFullInterest',
    noInterest: '/dashboard/matches?status=noInterest',
    settings: '/account/settings/',
  };

  for (const link in links) {
    if (router.currentRoute.value.fullPath.includes(links[link as keyof typeof links])) {
      return link;
    }
  }
});
</script>

<template>
  <ul class="tw-header-nav-submenu">
    <section role="list-section" class="tw-header-nav-submenu-section">
      <li>
        <NuxtLink to="/dashboard/matches?status=watchlist"
          :class="['tw-header-nav-submenu-link', { active: activeLink === 'watchlist' }]" @click="emit('item-clicked')">
          <Icon name="bi:heart" class="tw-header-nav-submenu-icon" />
          <span class="tw-header-nav-submenu-label">
            {{ t('watchlist') }}
          </span>
        </NuxtLink>
      </li>

      <li>
        <NuxtLink to="/dashboard/matches?status=otherFullInterest"
          :class="['tw-header-nav-submenu-link', { active: activeLink === 'otherFullInterest' }]"
          @click="emit('item-clicked')">
          <Icon name="bi:check-circle" class="tw-header-nav-submenu-icon" />
          <span class="tw-header-nav-submenu-label">
            {{ t('otherFullInterest') }}
          </span>
        </NuxtLink>
      </li>

      <li>
        <NuxtLink to="/dashboard/matches?status=noInterest"
          :class="['tw-header-nav-submenu-link', { active: activeLink === 'noInterest' }]"
          @click="emit('item-clicked')">
          <Icon name="bi:x-circle" class="tw-header-nav-submenu-icon" />
          <span class="tw-header-nav-submenu-label">
            {{ t('noInterest') }}
          </span>
        </NuxtLink>
      </li>

      <li>
        <NuxtLink to="/account/settings/personal-details"
          :class="['tw-header-nav-submenu-link', { active: activeLink === 'settings' }]" @click="emit('item-clicked')">
          <Icon name="bi:gear-wide-connected" class="tw-header-nav-submenu-icon" />
          <span class="tw-header-nav-submenu-label">
            {{ t('settings') }}
          </span>
        </NuxtLink>
      </li>
    </section>

    <section role="list-section" class="tw-header-nav-submenu-section">
      <NuxtLink to="/logout" class="tw-header-nav-submenu-link" @click="emit('item-clicked')">
        <Icon name="bi:power" class="tw-header-nav-submenu-icon" />
        <span class="tw-header-nav-submenu-label">
          {{ t('logout') }}
        </span>
      </NuxtLink>
    </section>
  </ul>
</template>

<style lang="scss" scoped>
@import '~/assets/styles/tw-variables.scss';
@import '~/assets/styles/tw-mixins.scss';

.tw-header-nav-submenu {
  &-section {
    border-top: 1px solid $lightGray;
    padding: 0 0 0.5rem;

    &:first-child {
      border-top: none;
    }
  }
}

.tw-header-nav-submenu-link.router-link-active {
  text-decoration-line: none;
}

.tw-header-nav-submenu-link.active {
  text-decoration-line: underline;
}

.tw-header-nav-submenu-icon {
  width: 1rem;
  height: 1rem;
}

@include media-query(lg) {
  .tw-header-nav-submenu {
    &-section {
      padding: 0.5rem 0;
    }
  }
}
</style>

<i18n lang="json">{
  "de": {
    "watchlist": "Merkliste",
    "otherFullInterest": "Interessenten",
    "noInterest": "Kein Interesse",
    "settings": "Einstellungen",
    "activateSubscription": "Aktivieren",
    "logout": "Abmelden"
  },
  "en": {
    "watchlist": "Watchlist",
    "otherFullInterest": "Other Full Interest",
    "noInterest": "No Interest",
    "settings": "Settings",
    "activateSubscription": "Activate Subscription",
    "logout": "Logout"
  }
}</i18n>